import {Component, OnInit} from '@angular/core';
import {VERSION} from '../../../../environments/version';
import {NavbarService} from '../../services/navbar.service';
import {UserLoginService} from '../../../login/services/user-login.service';
import {CognitoService} from '../../../common/services/authorisation/cognito-service';
import {AmplifyService} from 'aws-amplify-angular';
import {Router} from '@angular/router';
const NO_ROLE = 'No-role';

@Component({
  selector: 'header-user-button',
  templateUrl: './user-button.component.html',
  styleUrls: ['./user-button.component.scss']
})
export class UserButtonComponent implements OnInit {
  username: string;
  userRole: string;
  version: string = VERSION.version;
  opened: boolean = false;

  constructor(
    private navService: NavbarService,
    private userService: UserLoginService,
    private cognito: CognitoService,
    private router: Router,
    private amplify: AmplifyService) {
  }

  ngOnInit() {
    if (this.amplify.auth().user) {
      let payload = this.amplify.auth().user.signInUserSession.idToken.payload;
      this.username = this.amplify.auth().user.username;
      const groups: string[] = payload['cognito:groups'];
      if (groups && groups.length > 0) {
        this.userRole = groups.join(', ');
      }
      else {
        this.userRole = NO_ROLE;
      }
    }
  }

  signout() {
    this.router.navigate(['/landing']);
    this.amplify.auth().signOut();
  }

  toggled($event: boolean) {
    this.opened = $event;
  }

}
