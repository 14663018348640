import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {UserLoginService} from '../../services/user-login.service';

@Component({
  selector: 'app-user-forgot-step-one',
  templateUrl: './user-forgot-step-one.component.html',
  styleUrls: ['./user-forgot-step-one.component.scss']
})
export class UserForgotStepOneComponent implements OnInit {
  forgotForm: FormGroup;
  email: FormControl;
  err: string;

  constructor(private fb: FormBuilder,
              private userService: UserLoginService,
              private router: Router) {
    this.createForm();
  }

  private createForm(): void {
    this.email = new FormControl('', Validators.required);
    this.forgotForm = this.fb.group(
      {
        email: this.email
      }
    );
  }

  ngOnInit() {
  }

  forgot() {
    const formModel = this.forgotForm.value;
    const email = formModel.email;

    if (email) {
      this.userService.forgotPassword(email)
        .subscribe(
          val => {
            this.router.navigate(['/auth/forgot', email]);
          },
          err => {
            this.err = err.message;
          }
        );
    }
  }
}
