import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

const url = `${environment.api.ios_proxy}/dcs/readingSchedules`;

@Injectable({
  providedIn: 'root'
})
export class ActiveAggregatorsService {
  cache = undefined;
  aggregators: { domain: string, short: string }[] = environment.aggregators;

  constructor(private http: HttpClient) {
    this.activeAggregators().subscribe();
  }

  activeAggregators(): Observable<{ domain: string, short: string }[]> {
    return new Observable((obs) => {
      if (this.cache === undefined) {
        this.schedules()
          .subscribe(
            schedules => {
              this.cache = {};
              schedules.forEach(scheduleId => {
                const findAgr = this.aggregators.find(agr => scheduleId.includes(agr.short));
                if (findAgr && !this.cache[findAgr.short]) {
                  this.cache[findAgr.short] = Object.assign({}, findAgr);
                }
              });
              obs.next(Object.values(this.cache));
            },
            err => obs.error
          );
      }
      else {
        obs.next(Object.values(this.cache));
      }
    });
  }

  schedules(): Observable<string[]> {
    return of(environment.schedules);
    return this.http.get(url)
      .pipe(
        map((scheduleList: any[]) => {
            const active = scheduleList.filter(schedule => schedule.isActive);
            return active.map(activeSchedule => activeSchedule.scheduleId);
          }
        ));
  }

  isActive({shortName, agrDomain}: { shortName?: string, agrDomain?: string }): Observable<boolean> {
    return new Observable(obs => {
      this.activeAggregators()
        .subscribe(activeList => {
          const found = activeList.find(agr => agr.short.includes(shortName) || agr.domain.includes(agrDomain));
          if (found) {
            obs.next(true);
          }
          else {
            obs.next(false);
          }
        }, err => obs.error);
    });
  }
}
