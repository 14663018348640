import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProfileListComponent} from './components/profile-list/profile-list.component';

const routes: Routes = [
  {
    path: 'drp',
    component: ProfileListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EpmRouting {
}
