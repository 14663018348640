import {Component, OnInit} from '@angular/core';
import {UserLoginService} from '../../services/user-login.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AmplifyService} from 'aws-amplify-angular';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  returnUrl: string;

  constructor(private userService: UserLoginService,
              private route: ActivatedRoute,
              private router: Router,
              private amplify: AmplifyService) {
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/opp/btm/dashboard';
    this.returnUrl = decodeURI(this.returnUrl);
  }

  login() {
    let user = this.amplify.auth().user;
    if (user && user.getSignInUserSession()) {
      this.router.navigate([this.returnUrl]);
    }
    else {
      this.router.navigate(['/auth'], {queryParams: {returnUrl: this.returnUrl}});
    }
  }

}
