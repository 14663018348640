import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {EntityDataService} from '../../../btm/entities/components/entity-data/services/entity-data.service';
import {DemandRequirementProfile, DemandRequirementProfileFields} from '../../models/demand-requirement-profile';
import {ProfileService} from '../../services/profile.service';
import * as moment from 'moment';

export enum CreateProfileStateType {
  create, edit, view
}

export interface CreateProfileDialogData {
  state: CreateProfileStateType;
  profile?: DemandRequirementProfile | DemandRequirementProfileFields;
}

@Component({
  selector: 'epm-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {
  profile: DemandRequirementProfile | DemandRequirementProfileFields;
  valid: boolean;
  errors: string[];

  constructor(private profileService: ProfileService,
              public dialogRef: MatDialogRef<any>,
              private dataService: EntityDataService,
              @Inject(MAT_DIALOG_DATA) public data?: CreateProfileDialogData) {
  }

  ngOnInit() {
    if (this.data.state !== CreateProfileStateType.create) {
      this.profile = this.data.profile;
      this.valid = true;
    }
  }

  profileChanged($event: DemandRequirementProfileFields) {
    this.profile = $event;
  }

  isProfileValid($event) {
    this.valid = $event;
  }

  save() {
    if (this.valid) {
      const success = (response) => {
        this.errors = [];
        response.createdTime = moment.utc(response.createdTime);
        response.updatedTime = response.updatedTime ? moment.utc(response.updatedTime) : undefined;

        this.dialogRef.close(response);
      };
      const error = (response) => {
        this.errors = response.error.message;
      };
      if (this.data.state === CreateProfileStateType.edit) {
        this.profileService.updateProfile(this.profile, this.profile.name).subscribe(success, error);
      }
      else {
        this.profileService.createProfile(this.profile as DemandRequirementProfile).subscribe(success, error);
      }
    }
  }
}
