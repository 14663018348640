import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {auditTime} from 'rxjs/operators';
import {Login} from '../../models/Login';

@Component({
  selector: 'app-login-input',
  templateUrl: './login-input.component.html',
  styleUrls: ['./login-input.component.scss']
})
export class LoginInputComponent implements OnInit {
  @Input() login: Login;

  loginForm: FormGroup;
  username: FormControl;
  password: FormControl;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm(): void {
    this.username = new FormControl('', Validators.required);
    this.password = new FormControl('', Validators.required);
    this.loginForm = this.fb.group({
      username: this.username,
      password: this.password
    });

    this.username.valueChanges
      .pipe(auditTime(200))
      .subscribe(this.updateModelField('username'));
    this.password.valueChanges
      .subscribe(this.updateModelField('password'));
  }

  private updateModelField(field) {
    return (next) => {
      this.login[field] = next;
    }
  }
}
