import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {DcsCustomService} from '../../services/dcs-custom.service';
import {MatDialogRef} from '@angular/material';
import {Router} from '@angular/router';
import {DcsRequest} from '../../models/DcsRequest';
import {ActiveAggregatorsService} from '../../../shared/services/active-aggregators.service';

@Component({
  selector: 'app-custom-dcs-request',
  templateUrl: './custom-dcs-request.component.html',
  styleUrls: ['./custom-dcs-request.component.scss']
})
export class CustomDcsRequestComponent implements OnInit {

  aggregatorDomains: string[] = [];

  dataSourcelist: any[];
  selectedDataSource: number = 1;

  createRequestForm: FormGroup;
  scheduleId: FormControl;
  requestStartTime: FormControl;
  requestEndTime: FormControl;
  dataSource: FormControl;
  agrIdList: FormControl;
  isRequestSuccess: boolean = false;

  constructor(
    private dcsCustomService: DcsCustomService,
    private activeAggregatorSerivce: ActiveAggregatorsService,
    public dialogRef: MatDialogRef<any>,
    private router: Router) {

    this.scheduleId = new FormControl('', Validators.required);

    // this.execute = new FormControl('');
    this.requestStartTime = new FormControl('', Validators.required);
    this.requestEndTime = new FormControl('', Validators.required);

    this.dataSource = new FormControl('');
    this.agrIdList = new FormControl('');

    this.createRequestForm = new FormGroup({
      scheduleId: this.scheduleId,
      requestStartTime: this.requestStartTime,
      requestEndTime: this.requestEndTime,
      dataSource: this.dataSource,
      agrIdList: this.agrIdList
    });

    this.activeAggregatorSerivce.activeAggregators()
      .subscribe(active => {
        this.aggregatorDomains = active.map(agg => agg.domain);
      });

  }

  async ngOnInit() {
    this.dataSourcelist = [
      {'id': 'EXTERNAL', 'type': 'EXTERNAL'},
      {'id': 'INTERNAL', 'type': 'INTERNAL'}
    ];
    this.dataSource.setValue(this.dataSourcelist);
    this.selectedDataSource = this.dataSourcelist[0].id;
  }

  async submit() {
    let formModel = this.createRequestForm.value;
    if (this.createRequestForm.valid) {

      const requestStart = formModel.requestStartTime.toISOString(true);
      const requestEnd = formModel.requestEndTime.toISOString(true);

      let request: DcsRequest = {
        scheduleId: formModel.scheduleId,
        startTime: requestStart,
        endTime: requestEnd,
        aggregatorDomain: formModel.agrIdList,
        dataSource: formModel.dataSource
      };

      this.dcsCustomService.customRequest(request).subscribe(
        data => {
          this.isRequestSuccess = true;
          setTimeout(() => {
            if (this.dialogRef) {
              this.dialogRef.close();
            }
          }, 2000);
        },
        error => {
          this.createRequestForm.setErrors({submit: 'Error submitting request'});
        }
      );

    }
  }

  cancel() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    else {
      this.router.navigateByUrl('/opp/btm/reports/list');
    }
  }

}
