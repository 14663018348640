import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {auditTime} from 'rxjs/operators';

const profileTypes = ['All', 'Residential', 'Commercial', 'EV Charger'];

@Component({
  selector: 'epm-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss']
})
export class ProfileFormComponent implements OnInit {

  @Output() changes = new EventEmitter();
  @Output() isValid = new EventEmitter<boolean>();

  @Input('profile') profile?;

  profileForm: FormGroup;
  name: FormControl;
  description: FormControl;
  type: FormControl;
  ptuData: FormArray;
  profileTypes = profileTypes;

  ptuMin = 1;
  ptuMax = 48;

  constructor(private fb: FormBuilder) {
    this.name = new FormControl('', Validators.required);
    this.description = new FormControl('');
    this.type = new FormControl('', Validators.required);
    let firstRange = this.createRange();
    firstRange.valueChanges.subscribe(this.ptuValueChange);
    this.ptuData = this.fb.array([firstRange]);
    this.profileForm = new FormGroup({
      name: this.name,
      description: this.description,
      type: this.type,
      ptuData: this.ptuData
    });
  }

  ngOnInit() {
    if (this.profile) {
      const profile = Object.assign({}, this.profile);
      const getRange = (from, to, ptu) => {
        return {
          from,
          to,
          power: ptu.power,
          price: ptu.price,
          disposition: ptu.disposition
        };
      };

      const isSame = (firstPtu, secondPtu) => {
        return firstPtu.power === secondPtu.power
          && firstPtu.price === secondPtu.price
          && firstPtu.disposition === secondPtu.disposition;
      };

      const ptuRanges = this.profile.ptuData.reduce((previousValue, currentValue) => {
        if (previousValue === undefined) return [getRange(currentValue.ptu, currentValue.ptu, currentValue)];
        if (isSame(previousValue[previousValue.length - 1], currentValue)) {
          let range = previousValue[previousValue.length - 1];
          range.to = currentValue.ptu;
          previousValue[previousValue.length - 1] = range;
        }
        else {
          previousValue.push(getRange(currentValue.ptu, currentValue.ptu, currentValue));
        }
        return previousValue;
      }, undefined);
      profile.ptuData = ptuRanges;

      for (let i = 1; i < ptuRanges.length; i++) {
        this.addRange(false);
      }

      this.profileForm.patchValue(profile);
      this.ptuData.setValue(ptuRanges);

      this.name.disable({emitEvent: false});


    }
    else {
      this.profile = {
        ptuData: []
      };
    }

    this.ptuData.valueChanges
      .pipe(
        auditTime(300)
      )
      .subscribe((valChange) => {
        let changed = false;
        let change = this.ptuData.getRawValue();
        change = change.map(range => {
          if (range.to && (range.to > this.ptuMax || range.to < this.ptuMin)) {
            changed = true;
            range.to = this.ptuMax;
          }
          if (range.from && (range.from > this.ptuMax || range.from < this.ptuMin)) {
            changed = true;
            range.from = this.ptuMin;
          }
          return range;
        });
        if (changed) {
          this.ptuData.setValue(change);
        }
      });
    this.profileForm.valueChanges
      .pipe(auditTime(300))
      .subscribe(_ => {
        this.isValid.emit(this.profileForm.valid);
        this.emit();
      });

  }

  private createRange() {
    return this.fb.group({
      from: ['', Validators.required],
      to: ['', Validators.required],
      disposition: ['', Validators.required],
      power: ['', Validators.required],
      price: ['', Validators.required],
    });
  }

  ptuValueChange = (range) => {
    if (range.to && range.to > this.ptuMax) range.to = this.ptuMax;
    if (range.from && range.from < this.ptuMin) range.from = this.ptuMin;
    if (range.power && range.disposition && range.to && range.from) this.emit();
  };

  addRange(emit?: boolean) {
    let range = this.createRange();
    range.valueChanges.subscribe(this.ptuValueChange);
    this.ptuData.push(range);
    if (emit) {
      this.emit();
    }
  }

  removeRange(index: number) {
    this.ptuData.removeAt(index);
  }

  emit() {
    let formModel = this.profileForm.getRawValue();
    let ptuData = formModel.ptuData.filter(range => (range.to && range.from && range.power && range.disposition));
    ptuData = ptuData
      .map(range =>
        new Array(((range.to - range.from) + 1) > 0 ? (range.to - range.from) + 1 : 0)
          .fill({ptu: range.from, disposition: range.disposition, power: range.power, price: range.price})
          .map((value, index) => {
            let newValue = Object.assign(Object.assign({}, value), {ptu: value.ptu + index});
            return newValue;
          })
      )
      .flat();
    formModel.ptuData = ptuData;
    this.changes.emit(formModel);
  }
}
