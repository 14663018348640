import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserLoginService} from '../../services/user-login.service';

@Component({
  selector: 'app-user-forgot-step-two',
  templateUrl: './user-forgot-step-two.component.html',
  styleUrls: ['./user-forgot-step-two.component.scss']
})
export class UserForgotStepTwoComponent implements OnInit {
  verificationCode: FormControl;
  password: FormControl;
  forgotForm: FormGroup;

  private sub: any;
  private email: string;

  err: string;
  info: string;
  loading: boolean;
  ui: any = {
    finished: false,
    loading: false
  };

  constructor(private fb: FormBuilder,
              private userService: UserLoginService,
              public route: ActivatedRoute,
              private router: Router) {
  }

  private createForm(): void {
    this.verificationCode = new FormControl('', Validators.required);
    this.password = new FormControl('', Validators.required);
    this.forgotForm = this.fb.group(
      {
        verificationCode: this.verificationCode,
        password: this.password
      }
    );
  }

  ngOnInit() {
    this.ui.loading = false;
    this.ui.finished = false;
    this.createForm();
    this.sub = this.route.params.subscribe(params => {
      this.email = params['email'];
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  resend() {
    this.userService.forgotPassword(this.email)
      .subscribe(
        val => {
          this.info = `Code resent to ${this.email}`;
        },
        err => {
          this.err = err.message;
        }
      );
  }

  submit() {
    const model: any = this.forgotForm.value;
    const verificationCode = model.verificationCode;
    const password = model.password;

    this.info = null;
    this.err = null;
    this.ui.loading = true;
    this.userService.confirmNewPassword(this.email, verificationCode, password)
      .subscribe(
        val => {
          this.ui.finished = true;
          this.ui.loading = false;
        },
        err => {
          this.ui.loading = false;
          this.err = err.message;
        }
      );
  }
}
