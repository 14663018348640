import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileListComponent} from './components/profile-list/profile-list.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EpmRouting} from './epm.routing';
import {MatModule} from '../common/modules/mat.module';
import {CreateProfileComponent} from './components/create-profile/create-profile.component';
import {ProfileGraphComponent} from './components/profile-graph/profile-graph.component';
import {PublishProfileComponent} from './components/publish/publish-profile.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule, MatPaginatorModule, MatSelectModule, MatSortModule, MatTableModule} from '@angular/material';
import {ProfileFormComponent} from './components/profile-form/profile-form.component';
import {PtuRangeSelectorComponent} from './components/ptu-range-selector/ptu-range-selector.component';
import {PublishHistoryComponent} from './components/publish-history/publish-history.component';
import { ProfilePtusComponent } from './components/profile-ptus/profile-ptus.component';

@NgModule({
  declarations: [
    ProfileListComponent,
    CreateProfileComponent,
    ProfileGraphComponent,
    PublishProfileComponent,
    ProfileFormComponent,
    PtuRangeSelectorComponent,
    PublishHistoryComponent,
    ProfilePtusComponent
  ],
  imports: [
    CommonModule,
    EpmRouting,
    FormsModule,
    ReactiveFormsModule,
    MatModule,
    MatSelectModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSortModule,
    NgxDatatableModule,
  ],
  exports: [
    PublishHistoryComponent
  ],
  entryComponents: [
    CreateProfileComponent,
    PublishProfileComponent,
    ProfilePtusComponent
  ]
})
export class EpmModule {
}
