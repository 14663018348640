import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

const path = environment.api.ios_proxy + '/epm/profiles/publish/history';

const statusApi = environment.api.ios_proxy + '/crr/events/epm';

export class HistoryParams {
  scheduleName?: string;
  period?: { start?: string, end?: string };
  profileName?: string;
  congestionPoint?: string;
  published?: { after?: string, before?: string };
}

@Injectable({
  providedIn: 'root'
})
export class ProfileHistoryService {

  constructor(private http: HttpClient) {
  }

  history(params: HistoryParams = {}): Observable<any[]> {
    // @ts-ignore
    return this.http.get(`${path}`, {params});
  }

  status(ids: string[]): Observable<{ cpcId: string, status: string }[]> {
    return this.http.post<{ cpcId: string, status: string }[]>(statusApi, {ids});
  }
}
