import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Ptu} from '../../models/demand-requirement-profile';
import * as echarts from 'echarts';
import * as moment from 'moment';

const chartOps = {
  xAxis: {
    name: 'PTU',
    nameLocation: 'middle',
    nameGap: 25,
    type: 'category',
    triggerEvent: true,
    data: new Array(48).fill(true).map((value, index) => index + 1)
  },
  yAxis: {
    name: 'kW',
    type: 'value',
    axisLine: {onZero: true},
  },
  grid: {
    left: 45,
    right: 20,
    bottom: 40
  }
};

@Component({
  selector: 'epm-profile-graph',
  templateUrl: './profile-graph.component.html',
  styleUrls: ['./profile-graph.component.scss']
})
export class ProfileGraphComponent implements OnInit, OnChanges {

  @Input() ptuData: Ptu[];
  chart: echarts.ECharts;
  times: string[] = [];
  toggled = false;

  constructor() {
    for (let i = 0; i < 48; i++) {
      this.times.push(
        moment()
          .startOf('day')
          .add(i * 30, 'minutes')
          .format('HH:mm')
      );
    }
  }

  ngOnInit() {
    const el = document.getElementById('chart') as HTMLCanvasElement;
    this.chart = echarts.init(el);
    this.chart.setOption(Object.assign({}, chartOps));
    this.populateData();
  }

  populateData() {
    if (!(this.chart && this.ptuData && this.ptuData.length > 0)) return;
    const maxPower =
      this.ptuData
        .reduce((previousValue, currentValue) => {
          if (Math.abs(currentValue.power) > Math.abs(previousValue.power)) return currentValue;
          else return previousValue;
        })
        .power;
    const max = Math.ceil(((Math.abs(maxPower) + 1) * 1.1) / 10) * 10; //this shit is some gud maths.
    const min = -max;
    const yAxis = {max, min};
    const tooltip = {
      formatter: (params) => {
        console.log(this.times[params.data.value[0]]);
        return `${this.times[params.data.value[0]]} (${params.data.ptu.ptu}): ${params.data.ptu.power}kW`;
      }
    };
    const series = [
      {
        animation: true,
        type: 'bar',
        data: this.ptuData.map(ptuData => ({value: [ptuData.ptu - 1, ptuData.power], ptu: ptuData})),
        color: (seriesPoint) => {
          const data = seriesPoint.data.ptu;
          if (data.disposition && data.disposition === 'Requested') {
            return 'rgba(255,216,111, 0.8)';
            // // @ts-ignore
            // return new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //   offset: 1,
            //   color: 'rgb(255,216,111)'
            // }, {
            //   offset: 0,
            //   color: 'rgb(252,98,98)'
            // }]);
          }
          return 'rgba(111,222,255,0.8)';
        }
      }
    ];
    this.chart.setOption({series, tooltip, yAxis, xAxis: this.getXaxis()});
    this.chart.resize();
    // @ts-ignore
    this.chart.on('click', 'xAxis.category', (params) => {
      this.toggleX();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.ptuData.isFirstChange() && changes.ptuData.currentValue) {
      this.populateData();
    }
  }

  private lastToggled: number = new Date().valueOf();

  toggleX() {
    if ((new Date().valueOf() - this.lastToggled) > 400) {
      this.toggled = !this.toggled;
      this.chart.setOption({
        xAxis: this.getXaxis()
      });
      this.lastToggled = new Date().valueOf();
    }
  }

  private getXaxis() {
    return {
      data: this.toggled ? this.times : this.times.map((v, i, arr) => i + 1)
    };
  }

}
