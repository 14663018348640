import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './common/guards/AuthGuard';
import {AppHeaderComponent} from './header/components/app-header/app-header.component';
import {AuthComponent} from './login/components/auth/auth.component';
import {LandingComponent} from './login/components/landing/landing.component';
import {PageAuthorisationGuard} from './common/services/authorisation/page-authorisation.guard';
import {BookingsComponent} from './evm/booking/components/bookings/bookings.component';
import {ChangelogComponent} from './common/components/changelog/changelog.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/landing',
    pathMatch: 'full'
  },
  {
    path: 'opp',
    canActivate: [AuthGuard],
    component: AppHeaderComponent,
    children: [
      {
        path: 'btm',
        loadChildren: './btm/btm.module#BtmModule'
      },
      {
        path: 'cm/wpd',
        loadChildren: './wpd/wpd.module#WpdModule',
        canActivate: [PageAuthorisationGuard]
      },
      {
        path: 'cm',
        loadChildren: './cm/cm.module#CmModule',
        canActivate: [PageAuthorisationGuard]
      },
      {
        path: 'epm',
        loadChildren: './epm/epm.module#EpmModule',
        canActivate: [PageAuthorisationGuard]
      },
      {
        path: 'scheduler',
        loadChildren: './scheduler/scheduler.module#SchedulerModule',
        canActivate: [PageAuthorisationGuard]
      },
      {
        path: 'go-ev',
        loadChildren: './evm/evm.module#EvmModule',
        canActivate: [PageAuthorisationGuard]
      },
      {
        path: 'changelog',
        component: ChangelogComponent,
        canActivate: [PageAuthorisationGuard]
      }
    ]
  },
  {path: 'auth', component: AuthComponent},
  {path: 'landing', component: LandingComponent},
  {path: '**', redirectTo: '/landing'}
];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {enableTracing: false})
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRouting {
}
