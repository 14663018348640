import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterSelectComponent} from './filter-select.component';
import {FormsModule} from '@angular/forms';
import {MatModule} from '../../modules/mat.module';

@NgModule({
  declarations: [FilterSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatModule
  ],
  exports: [FilterSelectComponent]
})
export class FilterSelectModule {
}
