import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {CustomDcsRequestComponent} from '../../../btm/reports/components/custom-dcs-request/custom-dcs-request.component';
import {AuthorisationService} from '../../../common/services/authorisation/authorisation.service';

enum modules {
  GOEV = 'GOEV',
  scheduler = 'scheduler',
  BTM = 'BTM',
  CM = 'CM',
  EPM = 'EPM'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  opened: boolean;
  disabled = false;
  subRow: string;
  subOpen = true;
  activeParent: string;
  modules = modules;
  enabled = {
    dcsOnDemand: true,
    cm: true,
    epm: true,
    scheduler: true,
    evm: true
  };

  constructor(
    private router: Router,
    private authorisation: AuthorisationService,
    public dialog: MatDialog) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/cm/')) {
          if (!this.subRow) this.subRow = modules.CM;
          this.activeParent = modules.CM;
        }
        else if (event.url.includes('/btm/')) {
          if (!this.subRow) this.subRow = modules.BTM;
          this.activeParent = modules.BTM;
        }
        else if (event.url.includes('/epm/')) {
          if (this.subRow) this.subRow = modules.EPM;
          this.activeParent = modules.EPM;
        }
        else if (event.url.includes('/scheduler/')) {
          if (this.subRow) this.subRow = modules.scheduler;
          this.activeParent = modules.scheduler;
        }
        else if (event.url.includes('/go-ev/')) {
          if (!this.subRow) this.subRow = modules.GOEV;
          this.activeParent = modules.GOEV;
        }
      }
    });

    this.authorisation.permitted('cm', 'READ').subscribe(res => this.enabled.cm = res);
    this.authorisation.permitted('epm', 'READ').subscribe(res => this.enabled.epm = res);
    this.authorisation.permitted('scheduler', 'READ').subscribe(res => this.enabled.scheduler = res);
    this.authorisation.permitted('go-ev', 'READ').subscribe(res => this.enabled.evm = res);
    this.authorisation.permitted('dcs/onDemandMeterReadings', 'READ').subscribe(res => this.enabled.dcsOnDemand = res);
  }

  ngOnInit() {
  }

  toggle(menu: string) {
    if (menu !== this.subRow) {
      this.subRow = menu;
      this.subOpen = true;
    }
    else if (menu === this.subRow) {
      this.subOpen = !this.subOpen;
    }
  }

  openDialog() {
    const dialogRef = this.dialog
      .open(CustomDcsRequestComponent,
        {
          panelClass: ['modal-nopadding', 'modal-overflow', 'thin', 'scrollbar-warm-flame']
        });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
