import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';
import {Auth} from 'aws-amplify';
import * as moment from 'moment';

let lastRefresh = moment();

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private amplify: AmplifyService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.amplify.auth().user && this.amplify.auth().user.getSignInUserSession()) {
      const Authorization = this.amplify.auth().user.getSignInUserSession().getIdToken().getJwtToken();
      request = request.clone({
        setHeaders: {
          Authorization: Authorization
        }
      });
    }

    if (moment().isSameOrAfter(moment(lastRefresh).add(59, 'm'))) {
      this.refreshToken().then();
    }

    return next.handle(request);
  }

  async refreshToken() {
    let cognitoUser = await Auth.currentAuthenticatedUser();
    const {refreshToken} = cognitoUser.getSignInUserSession();
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      lastRefresh = moment();
    });
  }
}
