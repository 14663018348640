import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {DemandRequirementProfile, DemandRequirementProfileFields} from '../models/demand-requirement-profile';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AmplifyService} from 'aws-amplify-angular';
import {map} from 'rxjs/operators';

const base = `${environment.api.ios_proxy}/epm/profiles`;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'user-name': this.amplify.auth().user.username
    })
  };

  constructor(private http: HttpClient, private amplify: AmplifyService) {
  }

  static mapPtusToKilowatts = profile => {
    profile.ptuData.map(ptu => {
      ptu.power = ptu.power / 1000;
      return ptu;
    });
    return profile;
  };

  static mapPtusToWatts = profile => {
    profile.ptuData.map(ptu => {
      ptu.power = ptu.power * 1000;
      return ptu;
    });
    return profile;
  };

  profiles = (): Observable<DemandRequirementProfile[]> =>
    this.http.get<DemandRequirementProfile[]>(base, this.httpOptions)
      .pipe(map(profiles => profiles.map(ProfileService.mapPtusToKilowatts)));

  getProfile = (id): Observable<DemandRequirementProfile> =>
    this.http.get<DemandRequirementProfile>(`${base}/${id}`, this.httpOptions)
      .pipe(map(profile => ProfileService.mapPtusToKilowatts(profile)));

  createProfile = (profile: DemandRequirementProfile): Observable<any> =>
    this.http.post(base, ProfileService.mapPtusToWatts(profile), this.httpOptions)
      .pipe(map(profile => ProfileService.mapPtusToKilowatts(profile)));

  updateProfile = (partialProfile: DemandRequirementProfileFields, name): Observable<any> => {
    if (partialProfile.ptuData) partialProfile = ProfileService.mapPtusToWatts(partialProfile);
    return this.http.patch(`${base}/${name}`, partialProfile, this.httpOptions)
      .pipe(map(profile => ProfileService.mapPtusToKilowatts(profile)));
  };

  publishProfile = (publishParams: any): Observable<any> => this.http.post(base + '/publish', publishParams, this.httpOptions);
}
