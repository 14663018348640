import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ProfileService} from '../../services/profile.service';

export interface PublishDialogData {
  congestionPoints: string[];
  name: string;
}

@Component({
  selector: 'epm-publish-profile',
  templateUrl: './publish-profile.component.html',
  styleUrls: ['./publish-profile.component.scss']
})
export class PublishProfileComponent implements OnInit {

  @Input('congestionPoints') congestionPoints?: string[];
  @Input('name') name?: string;
  loading = false;
  success = false;

  publishForm: FormGroup;
  congestionPoint: FormControl;
  period: FormControl;
  tradeCycleRequestDuration: FormControl;
  tradeCycleOrderDuration: FormControl;
  minDate = moment();
  error: string;

  constructor(
    private profileService: ProfileService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data?: PublishDialogData) {
    this.congestionPoint = new FormControl('', Validators.required);
    this.period = new FormControl('', Validators.required);
    this.tradeCycleOrderDuration = new FormControl('', Validators.required);
    this.tradeCycleRequestDuration = new FormControl('', Validators.required);
    this.publishForm = new FormGroup({
      period: this.period,
      congestionPoint: this.congestionPoint,
      tradeCycleOrderDuration: this.tradeCycleOrderDuration,
      tradeCycleRequestDuration: this.tradeCycleRequestDuration
    });
  }

  ngOnInit() {
    if (this.dialogRef && this.data) {
      this.name = this.data.name;
      this.congestionPoints = this.data.congestionPoints;
    }
    this.publishForm.setValue({
      congestionPoint: this.congestionPoints[0],
      period: moment(),
      tradeCycleRequestDuration: 300,
      tradeCycleOrderDuration: 300
    });
  }

  publish() {
    this.loading = true;
    this.error = undefined;
    let model = this.publishForm.getRawValue();
    model.profileName = this.name;
    model.period = moment(model.period).format('YYYY-MM-DD');

    this.profileService.publishProfile(model)
      .subscribe(
        success => {
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            this.close(true);
          }, 2000);
        },
        error => {
          this.loading = false;
          this.error = error.message || 'Failed to publish';
        });
  }

  close(success?) {
    this.dialogRef.close(success);
  }
}
