import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {OnChanges} from '@angular/core';

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterDateComponent implements OnChanges, OnInit {

  @Input() customPlaceholder: string;

  @Input() displayedDate;

  @Input() selected;

  @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();

  @Input() reset?: EventEmitter<boolean>;

  constructor() {  }

  ngOnInit(): void {
    if (this.reset) {
      this.reset.subscribe(reset => {
        this.selected = null;
      });
    }
  }

  addDateEvent(type, event) {
    this.dateChanged.emit(event.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selected = changes.selected.currentValue;
  }
}
