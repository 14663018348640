import {Injectable} from '@angular/core';
import gql from 'graphql-tag';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';

@Injectable()
export class CpmService {

  constructor(private apollo: Apollo) {
  }

  getActiveCongestionPointsGraphQLQuery(fields: string[]): Observable<any> {
    let query = gql(`
        {
          congestionPoints{
            ${fields.join('\n')}
          }
        }
      `);

    return this.apollo.watchQuery({query: query}).valueChanges;
  }


  getActiveCongestionPoints() {
    return this.getActiveCongestionPointsGraphQLQuery(['CongestionPoint'])
      .pipe(map((results: any) => (results.data && results.data.congestionPoints).map(cp => cp.CongestionPoint) || []));
  }

  getAggregators() {
    const query = gql(`
      {
        aggregators{
          agrDomain
        }
      }
    `);
    return this.apollo.watchQuery({query}).valueChanges
      .pipe(map((results: any) => (results.data && results.data.aggregators).map(agg => agg.agrDomain) || []));
  }
}
