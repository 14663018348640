import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HistoryParams, ProfileHistoryService} from '../../services/profile-history.service';
import {map} from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import {ProfilePtusComponent} from '../profile-ptus/profile-ptus.component';

@Component({
  selector: 'epm-profile-history',
  templateUrl: './publish-history.component.html',
  styleUrls: ['./publish-history.component.scss']
})
export class PublishHistoryComponent implements OnInit, OnChanges {

  @Input() params?: HistoryParams;
  @Input() embedded?: boolean;
  @Input() embeddedTitle?: string;
  @Input() width?: number;
  @Output() closed = new EventEmitter<boolean>();

  history: any[];

  loading = false;

  columns = [
    {prop: 'id', minWidth: 65, maxWidth: 85, width: 80, name: 'ID'},
    {prop: 'idPTU', minWidth: 65, maxWidth: 85, width: 80, name: 'PTU ID'},
    {prop: 'congestionPoint'},
    {prop: 'period', name: 'For Period'},
    {prop: 'cycleDuration', name: 'Cycle duration', minWidth: 80, maxWidth: 100, width: 90},
    {prop: 'publishedBy', maxWidth: 110},
    {prop: 'publishedTime', name: 'Published On'},
    {prop: 'status', minWidth: 165},
    {prop: 'cpcId', minWidth: 70, name: 'CPC ID'},

  ];

  constructor(private historyService: ProfileHistoryService,
              public dialog: MatDialog) {
  }

  resize() {
    let ele = document.getElementById('publish-history-table');
    let width = window.innerWidth;
    if (this.width) width = this.width;
    ele.style.width = width + 'px';
  }

  ngOnInit() {
    if (this.params) this.paramsChange();
    if (!this.embedded) this.columns.splice(1, 0, {prop: 'drpName', name: 'Profile Name'});
  }

  paramsChange() {
    this.loading = true;
    this.historyService.history(this.params)
      .pipe(map(value => value.map(history => {
        history.cycleDuration = (history.tradeCycleRequestDuration + history.tradeCycleOrderDuration);
        return history;
      })))
      .subscribe(success => {
        this.history = success;
        if (this.history.some(h => h.schedulerName)) this.columns.push({prop: 'scheduleName'});
        const ids = success.map(history => history.messageId);
        this.historyService.status(ids)
          .subscribe(statuses => {
            statuses.forEach((value, index) => {
              this.history[index].status = value.status;
              this.history[index].cpcId = value.cpcId;
            });
            this.loading = false;
          }, error => this.loading = false);
      }, error => this.loading = false);
    if (this.embedded) {
      this.embeddedTitle = this.params.scheduleName || this.params.profileName || '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.width && changes.width.currentValue) this.resize();
    if (changes.params) this.paramsChange();
  }

  close() {
    this.closed.emit(true);
  }

  openPtuGraph(row) {
    const dialogRef = this.dialog
      .open(ProfilePtusComponent,
        {
          panelClass: ['modal-nopadding', 'modal-overflow', 'thin', 'scrollbar-warm-flame', 'modal-xl', 'modal-xl-enforce'],
          data: Object.assign({}, row)
        });

    // dialogRef.afterClosed().subscribe(result => {
  }
}
