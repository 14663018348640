import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {auditTime} from 'rxjs/operators';
import {UserSignupService} from '../../services/user-signup.service';
import {User} from '../../models/User';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})

export class UserSignupComponent implements OnInit {
  signupForm: FormGroup;

  username: FormControl;
  password: FormControl;
  passwordRepeat: FormControl;
  email: FormControl;

  state: any = {
    loading: false
  };

  private signupError: string = '';

  constructor(private fb: FormBuilder,
              private signup: UserSignupService,
              private router: Router) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm(): void {
    this.username = new FormControl('', Validators.required);
    this.password = new FormControl('', Validators.required);
    this.passwordRepeat = new FormControl('', Validators.required);
    this.email = new FormControl('', Validators.required);

    this.signupForm = this.fb.group(
      {
        username: this.username,
        password: this.password,
        passwordRepeat: this.passwordRepeat,
        email: this.email
      },
      {validator: this.passwordMatchValidator('password', 'passwordRepeat')}
    );
    const passwordRepeat = this.signupForm.get('passwordRepeat');
    passwordRepeat.valueChanges
      .pipe(auditTime(300))
      .subscribe((val) => {
      });
  }


  private passwordMatchValidator(passwordField: string, passwordRepeatField: string) {
    return (group: FormGroup): void => {
      let pwdControl = group.controls[passwordField];
      let pwdRepeatControl = <any> group.controls[passwordRepeatField];

      if (pwdRepeatControl.touched || (pwdRepeatControl._pendingTouched || pwdRepeatControl._pendingDirty)) {
        if (pwdRepeatControl.errors && pwdRepeatControl.errors.matching) {
          delete pwdRepeatControl.errors.matching;
          if (Object.keys(pwdRepeatControl.errors).length === 0) {
            pwdRepeatControl.setErrors(null);
          }
        }

        if (pwdRepeatControl.value !== pwdControl.value) {
          pwdRepeatControl.setErrors({matching: 'Passwords do not match'});
        }
      }
    };
  }

  onSubmit() {
    if (this.signupForm.valid) {
      const formModel = this.signupForm.value;
      //signup service register.
      //handle error


      const user: User = {
        email: formModel.email,
        username: formModel.username,
        password: formModel.password
      };

      this.signup.register(user)
        .subscribe(val => {
          this.state.loading = false;
          this.state.finished = true;
        }, err => {
          this.state.loading = false;
          this.signupForm.setErrors({cognitoError: err.message});
          this.signupError = err.message;

          //registration errors, such as user already exists
          console.warn('err in signup component', err);
        });

      this.state.loading = true;
    }
    else {
      this.signupForm.setErrors({required: 'Required fields are missing'});
    }
  }
}
