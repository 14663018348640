import {BrowserModule} from '@angular/platform-browser';
import localeGB from '@angular/common/locales/en-GB';

import * as moment from 'moment';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Injector, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {AppRouting} from './app.routing';
import {MatModule} from './common/modules/mat.module';
import {ApolloModule} from 'apollo-angular';
import {HttpLinkModule} from 'apollo-angular-link-http';
import {AuthGuard} from './common/guards/AuthGuard';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/components/header/header.component';
import {RegisterLocale} from './common/i18n/RegisterLocale';
import {LoaderComponent} from './common/components/loader/loader.component';
import {CognitoService} from './common/services/authorisation/cognito-service';
import {UserSignupService} from './login/services/user-signup.service';
import {UserLoginService} from './login/services/user-login.service';
import {AppHeaderComponent} from './header/components/app-header/app-header.component';
import {AuthComponent} from './login/components/auth/auth.component';
import {UserSignupComponent} from './login/components/user-signup/user-signup.component';
import {UserLoginComponent} from './login/components/user-login/user-login.component';
import {LoginInputComponent} from './login/components/login-input/login-input.component';
import {UserForgotStepOneComponent} from './login/components/user-forgot-step-one/user-forgot-step-one.component';
import {UserForgotStepTwoComponent} from './login/components/user-forgot-step-two/user-forgot-step-two.component';
import {LandingComponent} from './login/components/landing/landing.component';
import {NavbarService, NavOpenedState} from './header/services/navbar.service';
import {CommonModule} from '@angular/common';
import {GraphQLModule} from './common/modules/graphql.module';
import {CpmService} from './common/services/cpm.service';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';
import {AuthWrapperComponent} from './login/components/auth-wrapper/auth-wrapper.component';
import {QRCodeModule} from 'angularx-qrcode';
import {JwtInterceptor} from './common/interceptors/JwtInterceptor';
import {UserButtonComponent} from './header/components/user-button/user-button.component';

import {CustomDcsRequestComponent} from './btm/reports/components/custom-dcs-request/custom-dcs-request.component';
import {DcsCustomService} from './btm/reports/services/dcs-custom.service';
import {CustomDcsRequestModule} from './btm/reports/components/custom-dcs-request/custom-dcs-request.module';
import {PageAuthorisationGuard} from './common/services/authorisation/page-authorisation.guard';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FilterSelectModule} from './common/components/filter-select/filter-select.module';
import {FilterDateModule} from './common/components/filter-date/filter-date.module';
import {NaturalLanguageInputModule} from './common/components/natural-language-input/natural-language-input.module';
import {OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule} from 'ng-pick-datetime';
import {OwlMomentDateTimeModule} from 'ng-pick-datetime-moment';
import {EpmModule} from './epm/epm.module';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { ChangelogComponent } from './common/components/changelog/changelog.component';
import {MarkdownModule} from 'ngx-markdown';

const MY_MOMENT_FORMATS = {
  parseInput: 'L LT',
  fullPickerInput: 'L LT',
  datePickerInput: 'L',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoaderComponent,
    AppHeaderComponent,
    AuthComponent,
    UserSignupComponent,
    UserLoginComponent,
    LoginInputComponent,
    UserForgotStepOneComponent,
    UserForgotStepTwoComponent,
    LandingComponent,
    AuthWrapperComponent,
    UserButtonComponent,
    ChangelogComponent
  ],
  imports: [
    AppRouting,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GraphQLModule,
    MDBBootstrapModule.forRoot(),
    MatModule,
    NgxDatatableModule,
    ApolloModule,
    HttpLinkModule,
    AmplifyAngularModule,
    QRCodeModule,
    CustomDcsRequestModule,
    FilterSelectModule,
    FilterDateModule,
    NaturalLanguageInputModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    EpmModule,
    NgxJsonViewerModule,
    MarkdownModule.forRoot({ loader: HttpClient })
  ],
  providers: [
    DcsCustomService,
    AuthGuard,
    PageAuthorisationGuard,
    CognitoService,
    UserSignupService,
    UserLoginService,
    NavbarService,
    CpmService,
    AmplifyService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB'},
    {provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: {useUtc: true}}
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
  entryComponents: [
    LoaderComponent,
    CustomDcsRequestComponent
  ]
})
export class AppModule {
  constructor(private injector: Injector, nav: NavbarService) {

    RegisterLocale.setLocale(localeGB, 'en-GB');
    RegisterLocale.setLocale(localeGB, 'en');
    moment.locale('en-gb');
    console.info('Locale:', moment.locale());

    nav.state = NavOpenedState.CLOSED;
  }
}
