import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Permission} from '../../models/permission';
import {environment} from '../../../../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';

const path = environment.permissions.api;

export const Actions = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
};


@Injectable({
  providedIn: 'root'
})
export class AuthorisationService {
  private _permissions: Permission[];

  constructor(private http: HttpClient, private amplify: AmplifyService) {
  }

  private load(): Observable<Permission[]> {
    let payload = this.amplify.auth().user.signInUserSession.idToken.payload;
    const groups: string[] = payload['cognito:groups'];
    const role = groups[0];
    return this.http.get<Permission[]>(path, {params: {role}});
  }

  permissions({forceNew}: { forceNew?: boolean } = {}): Observable<Permission[]> {
    return new Observable(subscriber => {
      if (this._permissions && !forceNew) {
        subscriber.next(this._permissions.slice());
      }
      else {
        this.load().subscribe(perms => {
          this._permissions = perms;
          subscriber.next(this._permissions.slice());
        }, err => subscriber.error(err));
      }
    });
  }

  permitted(route: string, action: string): Observable<boolean> {
    return new Observable(subscriber => {
      this.permissions()
        .subscribe(permissions => {
          const permission = permissions.find(perm => route.includes(perm.path));
          if (!permission) subscriber.next(true);
          else subscriber.next(permission.actions.includes(action));
        }, error => subscriber.next(false));
    });
  }
}
