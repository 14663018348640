import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UserLoginService} from '../../services/user-login.service';
import {Login} from '../../models/Login';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  errMsg: string;
  returnUrl: string;
  loginDetails: Login;
  submitForm: FormGroup;
  state: any = {
    loading: false
  };

  constructor(private route: ActivatedRoute,
              private service: UserLoginService,
              private router: Router,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.submitForm = this.fb.group({});
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginDetails = new Login();
  }

  login() {
    this.error(false);
    this.state.loading = true;
    this.service.authenticate(this.loginDetails.username, this.loginDetails.password)
      .subscribe(val => {
          this.state.loading = false;
          localStorage.setItem('currentUser', JSON.stringify(val));
          this.router.navigateByUrl(this.returnUrl);
        },
        err => {
          this.state.loading = false;
          this.error(true, err.message);
        });
  }

  private error(toggle: boolean, err?: string) {
    if (!toggle) this.errMsg = null;
    else this.errMsg = err;
  }

}
