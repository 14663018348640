import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() colour: string;
  @Input() style: string;

  constructor() { }

  ngOnInit() {  }
}
