import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';

declare var $: any;

export class InputOptions {
  type: 'text' | 'email' | 'password' | 'number' | 'date' | 'time' | 'datetime-local' | 'tel' | 'month' | 'week' | 'url';
  placeholder: any;
  hint?: string;
  initialValue?: any;
  min?: number | Moment;
  max?: number;
  disabled?: boolean;
}

export class DropdownOptions {
  options: string[];
  initialIndex?: number;
  initialValue?: any;
  disabled?: boolean;
}

@Component({
  selector: 'nl-input',
  templateUrl: './natural-language-input.component.html',
  styleUrls: ['./natural-language-input.component.scss']
})
export class NaturalLanguageInputComponent implements OnInit, AfterViewInit {
  @Input() field: string;
  @Input() id?: string;
  @Input() type: string;
  @Input() typeOptions: InputOptions | DropdownOptions;
  @Input() closeListener: EventEmitter<boolean>;
  @Output() change = new EventEmitter();

  fld: HTMLElement;
  toggle;
  typeClass = '';
  opened = false;
  input: HTMLInputElement;
  inputValue: any;
  selectedIndex: number;
  value: any;
  _options: string[];
  formInput: FormControl;
  inputForm: FormGroup;

  constructor() {
  }

  ngOnInit() {
    if (this.type === 'dropdown') {
      this.typeOptions = this.typeOptions as DropdownOptions;
      this.selectedIndex = this.typeOptions.initialIndex || 0;

      if (this.typeOptions.initialValue) {
        let foundIndex = this.typeOptions.options.indexOf(this.typeOptions.initialValue);
        if (foundIndex >= 0) {
          this.selectedIndex = foundIndex;
        }
      }
      this._options = this.typeOptions.options.slice();
      this.value = this.typeOptions.options[this.selectedIndex];
    }
    else if (this.type === 'input') {
      this.formInput = new FormControl('');
      this.inputForm = new FormGroup({
        formInput: this.formInput
      });


      this.typeOptions = this.typeOptions as InputOptions;

      this.value = this.typeOptions.initialValue || this.typeOptions.placeholder;
      this.inputValue = this.typeOptions.initialValue || '';
    }
    else if (this.type === 'date' || this.type === 'time') {
      this.formInput = new FormControl('');
      this.inputForm = new FormGroup({
        formInput: this.formInput
      });
      this.typeOptions = this.typeOptions as InputOptions;

      this.value = this.typeOptions.initialValue || this.typeOptions.placeholder;
      this.inputValue = this.typeOptions.initialValue || '';

      if (this.typeOptions.initialValue !== undefined && this.type === 'date') {
        let date = moment(this.typeOptions.initialValue);
        this.formInput.setValue(date);
      }
      if (this.type === 'time') {
        this.formInput.setValue(moment(this.value, 'HH:mm'));
        this.inputValue = this.value;
      }

      if (!this.id) this.id = this.field;
    }
    else {
      throw new Error('Type error');
    }
  }

  setValue() {
    if (this.type === 'dropdown') {
      this.value = this._options[this.selectedIndex];
    }
    else if (this.type === 'input') {
      // @ts-ignore
      this.value = this.input.value.trim() !== '' ? this.input.value : this.typeOptions.placeholder;
      // @ts-ignore
      if (this.typeOptions.type === 'number') {
        this.value = Number.parseInt(this.value);
      }

      this.typeOptions = this.typeOptions as InputOptions;
      if (this.typeOptions.min && (this.value < this.typeOptions.min)) {
        this.formInput.setValue(this.typeOptions.min);
        this.value = this.typeOptions.min;
      }
      else if (this.typeOptions.max && (this.value > this.typeOptions.max)) {
        this.formInput.setValue(this.typeOptions.max);
        this.value = this.typeOptions.max;

      }
    }
    else if (this.type === 'date') {
      this.typeOptions = this.typeOptions as InputOptions;
      this.value = this.formInput.value ? moment(this.formInput.value).format('DD/MM/YYYY') : this.typeOptions.placeholder;
    }
    else if (this.type === 'time') {
      this.typeOptions = this.typeOptions as InputOptions;
      let time;
      if (typeof this.formInput.value === 'string') {
        time = moment(this.formInput.value, 'HH:mm');
      }
      else {
        time = this.formInput.value;
      }
      this.formInput.setValue(time, {emitEvent: false});
      this.value = time.format('HH:mm');
      this.input.value = this.value;
    }
  }

  open() {
    if (this.opened) {
      return false;
    }
    if (!this.typeOptions.disabled) {
      this.opened = true;
      this.checkPosition();
      this.emit('opened');
    }
  }

  close(index?, flag?) {
    if (!this.opened) {
      return false;
    }
    if (!flag) {
      this.opened = false;
    }

    if (this.type === 'dropdown') {
      this.selectedIndex = index;
    }
    else if (this.type === 'input' || this.type === 'date' || this.type === 'time') {
      this.input.blur();
    }
    this.setValue();
    this.emit('closed');
  }

  emit(action?) {
    this.change.emit({
      action,
      open: this.opened,
      value: this.value,
      rawValue: this.type === 'input' ? this.input.value : this.value,
      field: this.field
    });
  }

  checkPosition() {
    var ul = this.fld.querySelector('ul');
    var left = this.getOffset(ul).left;

    var windowWidth = document.documentElement.clientWidth
      || document.body.clientWidth;

    if (windowWidth < left + ul.scrollWidth) {
      var diff = windowWidth - (left + ul.scrollWidth);
      ul.style.left = diff + 'px';
    }
  }

  getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return {top: _y, left: _x};
  }

  ngAfterViewInit(): void {
    this.fld = document.getElementById(this.field);
    this.toggle = document.getElementById((this.field + '-toggle'));

    if (this.type === 'dropdown') {
      this.typeClass = 'nl-dd';
    }
    else if (this.type === 'input' || this.type === 'date' || this.type === 'time') {
      this.typeClass = 'nl-ti-text';
      this.input = document.getElementById((this.field + '-input-' + this.id)) as HTMLInputElement;
      if (this.type === 'time') {
        this.input.setAttribute('disabled', 'true');
      }
      else if(this.type === 'date' && this.formInput.value){
        this.input.value = moment(this.formInput.value).format('DD/MM/YYYY')
      }
      this.input.addEventListener('keydown', (event) => {
        if (event.code === 'Enter') {
          this.close();
        }
      });

      this.input.addEventListener('change', (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
      // @ts-ignore
      $(`#${this.field} .hint`).append(this.typeOptions.hint || ('Please input a ' + this.typeOptions.type));
    }

    this.toggle.addEventListener('click', (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      this.open();
    });
    this.toggle.addEventListener('touchstart', (ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      this.open();
    });

    this.setValue();

    this.closeListener.subscribe(change => {
      this.close(this.selectedIndex, false);
    });

    this.emit('init');
  }

}
