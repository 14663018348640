import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Observable} from 'rxjs';

@Injectable()
export class CognitoService {
  public static REGION = environment.cognito.region;
  public static USER_POOL_ID = environment.cognito.user_pool_id;
  public static CLIENT_ID = environment.cognito.client_id;

  public static POOL_DATA: any = {
    UserPoolId: CognitoService.USER_POOL_ID,
    ClientId: CognitoService.CLIENT_ID
  };

  private session;

  constructor() {
  }

  getUserPool() {
    return new CognitoUserPool(CognitoService.POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getCurrentUserSession() {
    return Object.assign({}, this.session);
  }

  setCurrentUserSession(session) {
    this.session = session;
  };

  fetchCurrentUserSession(): Observable<CognitoUserSession> {
    return new Observable((obs) => {
      this.getCurrentUser()
        .getSession(
          (err, session) => {
            if (err) obs.error(err);
            else if (session) obs.next(session);
          }
        );
    });
  }

}
