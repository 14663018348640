import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FilterDateComponent} from './filter-date.component';
import {FormsModule} from '@angular/forms';
import {MatModule} from '../../modules/mat.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';

@NgModule({
  declarations: [
    FilterDateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatModule, MatMomentDateModule,

  ],
  exports: [FilterDateComponent]
})
export class FilterDateModule {
}
