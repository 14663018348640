import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PtuRange} from '../../models/ptu-range';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'epm-ptu-range-selector',
  templateUrl: './ptu-range-selector.component.html',
  styleUrls: ['./ptu-range-selector.component.scss']
})
export class PtuRangeSelectorComponent implements OnInit {
  private _ptuRange: PtuRange;

  max = 48;
  @Input() min?: number;
  @Input() ptuRange?: PtuRange;
  @Output() change = new EventEmitter<PtuRange>();
  form: FormGroup;
  from: FormControl;
  to: FormControl;
  power: FormControl;
  car: FormControl;
  toMin: number;

  constructor() {
    this.to = new FormControl('', Validators.required);
    this.from = new FormControl('', Validators.required);
    this.power = new FormControl('', Validators.required);
    this.car = new FormControl('', Validators.required);
    this.form = new FormGroup({
      to: this.to,
      from: this.from,
      power: this.power,
      car: this.car
    });
  }

  ngOnInit() {
    if (!this.min) {
      this.min = 1;
      this.toMin = 1;
    }
    if (this.ptuRange) {
      this._ptuRange = Object.assign({}, this.ptuRange);
      this.form.setValue(this._ptuRange);
      this.toMin = this.ptuRange.to;
    }
    this.from.setValidators(Validators.min(this.toMin));


    this.form.valueChanges
      .subscribe(change => {
        this.toMin = this.from.value;
        this.from.setValidators(Validators.min(this.toMin));
        if (this.to < this.from) {
          this.to.setValue(this.from.value);
        }
        this.ptuRange = Object.assign({}, this.form.value);
        this.change.emit(this.form.getRawValue());
      });
  }

}
