import {Component, OnInit} from '@angular/core';
import {AmplifyService} from 'aws-amplify-angular';
import {ActivatedRoute, Router} from '@angular/router';
import {UserLoginService} from '../../services/user-login.service';
import Auth from '@aws-amplify/auth';
import {environment} from '../../../../environments/environment';

const signedIn = 'signedIn';
const mfaSetup = 'setupMFA';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  private returnUrl: string;
  private qrCode: string;
  private mfaErr: string;

  constructor(private route: ActivatedRoute,
              private service: UserLoginService,
              private router: Router,
              private amplify: AmplifyService) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  settingUpMFA: boolean = false;
  settingUpMFAUser: any = undefined;
  mfaConfirmed: boolean = false;

  async ngOnInit() {
    this.amplify.authStateChange$.subscribe(
      async success => {
        if (!this.settingUpMFA && success.state === mfaSetup) {
          this.settingUpMFAUser = success.user;
          let result = await Auth.setupTOTP(this.settingUpMFAUser);
          let issuer = environment.environment_name === 'prod' ? 'smartenergyislands.cloud' : `${environment.environment_name}.smartenergyislands.cloud`;
          this.qrCode = `otpauth://totp/${success.user.username}@${issuer}?secret=${result}&issuer=${issuer}`;
          this.settingUpMFA = true;
        }

        if (!this.settingUpMFA && success.state === signedIn) {
          let session = success.user && success.user.signInUserSession;
          if (session) {
            this.router.navigate([this.returnUrl]);
          }
        }
      }
    );
  }

  async mfaProceed(code: any) {
    if (code.length === 6) {
      try {
        code = Number.parseInt(code) + '';
        this.settingUpMFAUser.challengeName = 'SOFTWARE_TOKEN_MFA';
        let result = await Auth.verifyTotpToken(this.settingUpMFAUser, code);
        this.mfaConfirmed = true;
        setTimeout(() => {
          this.router.navigate([this.returnUrl]);
        }, 2000);
      } catch (err) {
        this.mfaErr = 'Please try again';
      }
    }
    else {
      this.mfaErr = 'Please try again';
    }
  }
}
