import {Component, HostListener, OnInit} from '@angular/core';
import {DemandRequirementProfile} from '../../models/demand-requirement-profile';
import {ProfileService} from '../../services/profile.service';
import {MatDialog} from '@angular/material';
import {CreateProfileComponent, CreateProfileStateType} from '../create-profile/create-profile.component';
import {CpmService} from '../../../common/services/cpm.service';
import {PublishProfileComponent} from '../publish/publish-profile.component';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';

declare var $: any;

@Component({
  selector: 'epm-profile-list.fullscreen',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss']
})
export class ProfileListComponent implements OnInit {
  columns: any[] = [
    {prop: 'name', name: 'Name', width: 100, minWidth: 50, maxWidth: 180},
    {prop: 'description', minWidth: 100, width: 200},
    {prop: 'type', minWidth: 50, width: 100, maxWidth: 120},
    {prop: 'createdBy', maxWidth: 100},
    {prop: 'createdTime', name: 'Created On', minWidth: 180},
    {prop: 'updatedBy', maxWidth: 100},
    {prop: 'updatedTime', name: 'Updated On', minWidth: 180},
    {prop: 'lastPublishedTime', name: 'Last Published'},
    {name: 'Actions', minWidth: 195}
  ];
  profiles: DemandRequirementProfile[];
  selected: DemandRequirementProfile;
  profileHistory: DemandRequirementProfile = undefined;
  congestionPoints: string[];
  padding = 8;
  embeddedViewMaxWidth: number;

  private toUtcFormatted = (time) => moment(moment.utc(time).toISOString(true)).format('YYYY/MM/DD HH:mm:ss');
  autocompleteResults: any;
  autocompleteMapping = {};
  searchForm: FormGroup;
  search: FormControl;
  private _profiles: DemandRequirementProfile[];
  loading: boolean;

  constructor(private profileService: ProfileService,
              private cpmService: CpmService,
              public dialog: MatDialog) {
    this.search = new FormControl('');
    this.searchForm = new FormGroup({
      search: this.search
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let ele = document.getElementById('inner-table');
    const halfWidth = (window.innerWidth - (this.padding * 3)) / 2;
    this.embeddedViewMaxWidth = halfWidth;
    let embeddedView = document.getElementById('embedded-view');

    if (!this.profileHistory) {
      ele.style.width = window.innerWidth - (this.padding * 2) + 'px';
      embeddedView.style.width = '0';
      embeddedView.style.height = '0';
    }
    else {
      ele.style.width = halfWidth + 'px';
      embeddedView.style.position = 'absolute';
      embeddedView.style.top = ele.offsetTop + 'px';
      embeddedView.style.left = (ele.offsetLeft + ele.offsetWidth) + 'px';
      embeddedView.style.width = ele.offsetWidth + 'px';
      embeddedView.style.height = ele.offsetHeight + 'px';
    }
    this.setAutocompletePosition();
  }

  private setAutocompletePosition() {
    if (this.autocompleteResults) {
      const search = $('#autocompleteSearch');
      $('#autocompletePopup').css({
        top: (search.position().top + search.height() - 19.5),
        left: search.position().left,
        position: 'absolute'
      });
    }
  }

  searchKeyPress($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.autocompleteResults = undefined;
    }
  }

  clearSearch(){
    this.search.setValue('');
  }

  searchSuggestionSelected(suggestion, type?) {
    this.search.setValue(suggestion, {emitEvent: false});
    this.autocompleteResults = undefined;
    this.filterProfileList(type);
  }

  filterProfileList(type?) {
    const searched = (this.search.value + '').trim().toLowerCase();

    if (type) {
      this.profiles = this._profiles.filter(profile => (profile[type] + '').trim().toLowerCase() === searched);
    }
    else {
      this.profiles = this._profiles.filter(profile => {
        let serialised = Object.keys(profile)
          .filter(key => key !== 'ptuData')
          .map(key => profile[key] + '')
          .join('')
          .toLowerCase();
        return serialised.includes(searched);
      });
    }
  }

  ngOnInit() {
    this.loading = true;
    this.onResize(null);
    this.cpmService.getActiveCongestionPointsGraphQLQuery(['CongestionPoint'])
      .pipe(map(load => load.data.congestionPoints))
      .subscribe(cps => {
        this.congestionPoints = cps.map(cp => cp.CongestionPoint);
      });

    this.profileService.profiles()
      .pipe(
        map(profiles => {
          profiles = profiles.map(
            profile => {
              Object.keys(profile)
                .filter(key => key !== 'ptuData')
                .forEach(key => {
                  const value = profile[key];
                  if (!this.autocompleteMapping[value]) this.autocompleteMapping[value] = [];
                  this.autocompleteMapping[value].push(key);
                });
              if (profile.createdTime) profile.createdTime = this.toUtcFormatted(profile.createdTime);
              if (profile.updatedTime) profile.updatedTime = this.toUtcFormatted(profile.updatedTime);
              return profile;
            }
          );
          return profiles;
        })
      )
      .subscribe(profiles => {
        this.loading = false;
        this.profiles = profiles.sort((a, b) => (b.createdTime + '').localeCompare(a.createdTime + ''));
        this._profiles = [...this.profiles];
      }, err => this.loading = false);

    this.search.valueChanges
      .subscribe(change => {
        if (!change || change === '') {
          this.autocompleteResults = undefined;
        }
        else {
          let lcSearch = change.toLowerCase();
          let autocompleteResults = {};
          Object
            .keys(this.autocompleteMapping)
            .filter(value => value.toLowerCase().includes(lcSearch))
            .forEach(value => {
              let type = this.autocompleteMapping[value][0];
              if (!autocompleteResults[type]) autocompleteResults[type] = {type: type, suggestions: []};
              autocompleteResults[type].suggestions.push(value);
            });

          this.autocompleteResults = Object.values(autocompleteResults);
          this.setAutocompletePosition();
        }
        this.filterProfileList();
      });
    $('body').click((e) => {
      if (!(e.target.id == 'autocompletePopup' || $(e.target).parents('#autocompletePopup').length)) {
        this.autocompleteResults = undefined;
      }
    });


  }

  view(row) {
    const dialogRef = this.dialog
      .open(CreateProfileComponent,
        {
          panelClass: ['modal-nopadding', 'modal-overflow', 'thin', 'scrollbar-warm-flame', 'modal-xl', 'modal-xl-enforce'],
          data: {profile: row, state: CreateProfileStateType.edit}
        });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let newProfiles = [...this.profiles];
        let found = newProfiles.findIndex(value => value.name === result.name);
        if (result.createdTime) result.createdTime = this.toUtcFormatted(result.createdTime);
        if (result.updatedTime) result.updatedTime = this.toUtcFormatted(result.updatedTime);
        newProfiles[found] = result;
        this.profiles = newProfiles;
      }
    });
  }

  create() {
    const dialogRef = this.dialog
      .open(CreateProfileComponent,
        {
          panelClass: ['modal-nopadding', 'modal-overflow', 'thin', 'scrollbar-warm-flame', 'modal-xl', 'modal-xl-enforce'],
          data: {state: CreateProfileStateType.create}
        });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let newProfiles = [...this.profiles];
        if (result.createdTime) result.createdTime = this.toUtcFormatted(result.createdTime);
        if (result.updatedTime) result.updatedTime = this.toUtcFormatted(result.updatedTime);
        newProfiles.push(result);
        this.profiles = newProfiles;

        Object.keys(result)
          .filter(key => key !== 'ptuData')
          .forEach(key => {
            const value = result[key];
            if (!this.autocompleteMapping[value]) this.autocompleteMapping[value] = [];
            this.autocompleteMapping[value].push(key);
          });
      }
    });
  }

  publish({name}) {
    const dialogRef = this.dialog
      .open(PublishProfileComponent,
        {
          panelClass: ['modal-overflow', 'thin', 'scrollbar-warm-flame'],
          data: {
            name,
            congestionPoints: this.congestionPoints
          }
        });
  }

  selectProfileHistory(profile?: DemandRequirementProfile) {
    this.profileHistory = profile;
    this.onResize(undefined);
  }
}
