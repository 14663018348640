import {registerLocaleData} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable()
export class RegisterLocale{

  currentLocale: string = '';
  static setLocale(locale, localeId){
    registerLocaleData(locale, localeId, null);

  }

}
