import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DcsRequest} from '../models/DcsRequest';

@Injectable()
export class DcsCustomService {
  private api: string = environment.dcs_custom_request.api;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'env': environment.environment_name
    }),
  };

  constructor(private http: HttpClient) {
  }

  customRequest(report: DcsRequest): Observable<any> {
    return this.http.post(this.api, report, this.httpOptions);
  }
}


