import {Injectable} from '@angular/core';
import {CognitoService} from '../../common/services/authorisation/cognito-service';
import * as Cognito from 'amazon-cognito-identity-js';
import {ISignUpResult} from 'amazon-cognito-identity-js';
import {Observable, Subscriber} from 'rxjs';
import {User} from '../models/User';

@Injectable()
export class UserSignupService {

  constructor(private cognitoService: CognitoService) {  }

  register(user: User): Observable<ISignUpResult> {
    let attrList = [];
    const attrEmail = new Cognito.CognitoUserAttribute({Name: 'email', Value: user.email.toLowerCase()});
    attrList.push(attrEmail);

    return new Observable((obs: Subscriber<ISignUpResult>) => {
      this.cognitoService.getUserPool()
        .signUp(
          user.username,
          user.password,
          attrList,
          null,
          (err, result) => {
            if (err) {
              console.warn('err during signup', err);
              obs.error(err);
            }
            else {
              obs.next(result);
            }
          });
    });
  }
}
