import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private navState: NavOpenedState;
  public changes: Subject<NavOpenedState> = new Subject<NavOpenedState>();
  public disableChanged: Subject<boolean> = new Subject<boolean>();

  private isDisabled: boolean = false;


  constructor() {
  }

  public get disabled() {
    return this.isDisabled;
  }

  public set disabled(isDisabled) {
    this.isDisabled = isDisabled;
    this.disableChanged.next(this.isDisabled);
  }

  public get state() {
    return this.navState;
  }

  public set state(state: NavOpenedState) {
    this.disabled = false;
    this.navState = state;
    this.changes.next(this.navState);
  }

  toggle() {
    const toggleAsBoolean = !this.state;
    this.state = getNavState(toggleAsBoolean);
  }

}

function getNavState(open: boolean): NavOpenedState {
  if (open) return NavOpenedState.OPENED;
  else return NavOpenedState.CLOSED;
}

export enum NavOpenedState {
  OPENED = 1,
  CLOSED = 0
}

export const boolToNav = getNavState;
