import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges, OnInit,
} from '@angular/core';
import {MatSelectChange} from '@angular/material';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})

export class FilterSelectComponent implements OnChanges, OnInit {

  @Input() customOptions: Array<any> = [];

  @Input() customPlaceholder: string;

  @Input() selected;

  @Output() selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  @Input() reset?: EventEmitter<boolean>;

  constructor() {
  }

  ngOnInit(): void {
    if (this.reset) {
      this.reset.subscribe(reset => {
        this.selected = null;
      });
    }
  }

  updateSelection(event) {
    this.selectionChange.emit(event.value);
  }

  someMethod() {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

}
