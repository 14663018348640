import {Injectable} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as moment from 'moment';
import {Observable} from 'rxjs';
import {EntityDayAggregationData} from '../models/entity-day-aggregation-data';
import {EntityPtuData} from '../models/entity-ptu-data';

const base = `/analysis/${environment.environment_name}/btmAggregatedData/`;

@Injectable({
  providedIn: 'root'
})
export class EntityDataService {
  private baseUrl = `${environment.api.lambda_proxy}${base}`;
  private dailyPath = 'days';
  private ptuPath = 'ptus';
  private headers = new HttpHeaders({
    'Content-Type': 'application/json'
  });

  constructor(private http: HttpClient) {
  }

  getDaily({entityId, connectionId, start, end}): Observable<EntityDayAggregationData[]> {
    if (!entityId || !connectionId) throw new Error('Entity and connectionId required to get daily entity data');
    if (!start) start = moment().startOf('month').format('YYYY-DD-MM');
    if (!end) end = moment().format('YYYY-DD-MM');
    return this.http.get<EntityDayAggregationData[]>(`${this.baseUrl}${entityId}/${connectionId}/${this.dailyPath}`, {
      headers: this.headers,
      params: {
        start,
        end
      }
    });
  }

  getPtus({entityId, connectionId, date}): Observable<EntityPtuData[]> {
    if (!entityId || !connectionId) throw new Error('Entity and connectionId required to get daily entity data');
    date = moment.utc(date).format('YYYY-MM-DD');
    return this.http.get<EntityPtuData[]>(`${this.baseUrl}${entityId}/${connectionId}/${this.ptuPath}`, {
      headers: this.headers,
      params: {
        date
      }
    });
  }

}
