import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthorisationService} from './authorisation.service';

@Injectable({
  providedIn: 'root'
})
export class PageAuthorisationGuard implements CanActivate {
  constructor(private authorisationSevice: AuthorisationService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable(subscriber => {
      const path = state.url;
      this.authorisationSevice.permitted(path, 'READ')
        .subscribe(result => {
          subscriber.next(result);
        }, error => subscriber.error(error));
    });
  }
}
