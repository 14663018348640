import {Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {AmplifyService} from 'aws-amplify-angular';
import {AuthorisationService} from './common/services/authorisation/authorisation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  env: any = environment;

  constructor(private amplify: AmplifyService, private authorisationService: AuthorisationService) {
    console.info('Running in mode: ', environment.environment_name);
    this.amplify.authStateChange$
      .subscribe(change => {
        if (change.state === 'signedIn') {
          this.authorisationService.permissions({forceNew: true}).subscribe();
        }
      });
  }

  ngOnInit(): void {}
}
