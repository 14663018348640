import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Ptu} from '../../models/demand-requirement-profile';
import * as moment from 'moment';

export interface ProfilePtusDialogData {
  ptuData: Ptu[]
  drpName: string;
  publishedTime: string;
  idPTU: string;
}

@Component({
  selector: 'app-profile-ptus',
  templateUrl: './profile-ptus.component.html',
  styleUrls: ['./profile-ptus.component.scss']
})
export class ProfilePtusComponent implements OnInit {

  publishedOn: string;
  publishedAt: string;
  locale: string = '+0000';
  ptuData: Ptu[];

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data?: ProfilePtusDialogData) {
  }

  ngOnInit() {
    if (!this.data || this.data.ptuData.length === 0) {
      this.dialogRef.close(new Error('Invalid input'));
    }

    this.ptuData = this.data.ptuData.map(ptu => {
      const newPtu = Object.assign({}, ptu);
      newPtu.power = ptu.power / 1000;
      return newPtu;
    });

    const localPublishedTime = moment(this.data.publishedTime + 'Z');
    this.publishedOn = localPublishedTime.format('L');
    this.publishedAt = localPublishedTime.format('LT');
    this.locale = localPublishedTime.format('ZZ');
  }

}
