export const environment = {
  environment_name: 'prod',
  production: true,
  api_key: 'dxu6slDfuq81hcKX7SS4pU9CcZcgipU85ZpPxYA5',
  aggregators: [
    {short: 'passiv', domain: 'passivsystems.com'},
    {short: 'moixa', domain: 'moixa.com'},
    {short: 'oati', domain: 'oati.com'},
  ],
  schedules: ['passiv-batch-15463', 'moixa'],
  cognito: {
    region: 'eu-west-2',
    user_pool_id: 'eu-west-2_RfgfK8t3t',
    client_id: '2rbhs1o5gp6i7du29trd170a4t'
  },
  api: {
    btm_data: 'https://73loh65u75.execute-api.eu-west-2.amazonaws.com/prod',
    validate_entity: 'https://mt0u72qosl.execute-api.eu-west-2.amazonaws.com/utils/entityid/verify?id=',
    lambda_proxy: 'https://smartenergyislands.cloud/auth/lambda',
    ios_proxy: 'https://smartenergyislands.cloud/auth/ios',
    self: 'https://smartenergyislands.cloud/auth/api'
  },
  permissions: {
    api: 'https://smartenergyislands.cloud/permissions'
  },
  btm_readings: {
    readings: '/btm/readings',
    validations: '/btm/validations'
  },
  bgs_report: {
    api: 'https://smartenergyislands.cloud/auth/ios/bgs/generateAdhocReport',
    list: 'https://smartenergyislands.cloud/auth/ios/bgs/reports',
    poll_interval: 60000
  },
  bgs_custom_report: {
    api: 'https://smartenergyislands.cloud/auth/bgs/reports',
    poll_interval: 1000
  },
  bgs_billing_report: {
    api: 'https://smartenergyislands.cloud/auth/ios/bgs/billingreports',
    poll_interval: 1000
  },
  bgs_s3_download_link: {
    api: 'https://smartenergyislands.cloud/auth/api/s3/presigned/bgs'
  },
  dcs_custom_request: {
    api: 'https://smartenergyislands.cloud/auth/ios/dcs/onDemandMeterReadings'
  },
  btm: {
    poll_interval: 20000,
    poll_table_interval: 300000,
    show_last: 20,
    api: 'https://smartenergyislands.cloud/auth/ios',
    dcs: {
      name: 'Data Collector',
      route: 'dcs',
      api: 'https://smartenergyislands.cloud/auth/api/ecs/dcs'
    },
    dvs: {
      name: 'Data Validator',
      route: 'dvs',
      api: 'https://smartenergyislands.cloud/auth/api/ecs/dvs'
    },
    dms: {
      name: 'Data Manager',
      route: 'dms'
    },
    bgs: {
      name: 'Bill Generator',
      route: 'bgs',
      api: 'https://smartenergyislands.cloud/auth/api/ecs/bgs',
      s3bucket: 'prod-ios-btm-generated-files'
    }
  },
  entity: {
    use_local: false,
    populate_with_mock: false,
    api: 'https://smartenergyislands.cloud/auth/ios/ems',
  },
  wpd: {
    socket: 'wss://smartenergyislands.cloud/ws/opp/wpd/subscribe',
    wimstatus: 'https://smartenergyislands.cloud/auth/ios/opp/wpd/mock/wimstatus',
    api: 'https://smartenergyislands.cloud/auth/ios/opp/wpd/data',
    cache: 'https://smartenergyislands.cloud/auth/ios/opp/wpd/cache',
    respond: 'https://smartenergyislands.cloud/auth/ios/opp/wpd/respond',
    topics: {
      anmalerts: {
        topic: 'prod/alerts'
      },
      response: {
        topic: 'prod/plannedDSR',
        table: 'response',
        sub: 'response'
      },
      anmstatus: {
        topic: 'prod/anmStatus',
        table: 'anmStatus',
        sub: 'anm'
      },
      setpoint: {
        topic: 'prod/generatorSetpoint',
        table: 'generatorOutput',
        sub: 'setpoint'
      },
      generation: {
        topic: 'prod/generatorOutput',
        table: 'generatorOutput',
        sub: 'generator'
      },
      health: {
        topic: 'prod/health',
        table: 'health',
        sub: 'health'
      }
    },
    initialLoad: {
      amount: 4,
      unit: 'hour'
    }
  },
  cm: {
    graphql: 'https://smartenergyislands.cloud/auth/ios/opp/graphql',
    socket: 'wss://smartenergyislands.cloud/ws/opp/graphql/subscribe'
  },
  amplify: {
    Auth: {
      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-2',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-2_ZwjAsMlHK',
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '3rskpbd5rtjlbbhundbpetr6cg',
      // authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
  }
};
