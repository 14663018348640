import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {UserLoginService} from '../../login/services/user-login.service';
import {Observable, Subscriber} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticate: UserLoginService, private amplify: AmplifyService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((obs: Subscriber<boolean>) => {
      const auth = this.amplify.auth();
      let user = auth.user && auth.user.getSignInUserSession();
      if (user) obs.next(true);
      else {
        this.router.navigate(['/landing'], {queryParams: {returnUrl: state.url}});
        obs.next(false);
      }
    });
  }
}
