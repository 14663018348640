import {Injectable} from '@angular/core';
import {AuthenticationDetails, CognitoUser} from 'amazon-cognito-identity-js';
import {CognitoService} from '../../common/services/authorisation/cognito-service';
import {Observable, Subscriber} from 'rxjs';

@Injectable()
export class UserLoginService {

  constructor(private cognito: CognitoService) {
  }

  authenticate(username: string, password: string): Observable<any> {
    let authenticationData = {
      Username: username,
      Password: password
    };

    let AWSAuthenticationDetails = new AuthenticationDetails(authenticationData);
    let cognitoUser;

    try {
      cognitoUser = new CognitoUser({Username: authenticationData.Username, Pool: this.cognito.getUserPool()});
    } catch (err) {
      err.message = 'Username and password are required';
      return new Observable(obs => obs.error(err));
    }

    return new Observable((obs: Subscriber<any>) => {
      cognitoUser.authenticateUser(AWSAuthenticationDetails, {
        onSuccess: res => {
          this.cognito.setCurrentUserSession(res);
          obs.next(res);
        },
        onFailure: err => obs.error(err)
      });
    });
  }

  isAuthenticated(): Observable<any> {
    let user = this.cognito.getCurrentUser();
    return new Observable((obs: Subscriber<any>) => {
      if (user != null) {
        user.getSession((err, session) => {
          if (err) obs.error(err);
          else {
            this.cognito.setCurrentUserSession(session);
            obs.next(session);
          }
        });
      } else {
        obs.error(`Can't retrieve the current user`);
      }
    });
  }

  logout() {
    this.cognito.getCurrentUser().signOut();
  }

  forgotPassword(email: string): Observable<any> {
    return new Observable(obs => {
      let user = new CognitoUser({Username: email, Pool: this.cognito.getUserPool()});
      user.forgotPassword({
        onSuccess: (val) => obs.next(val),
        onFailure: (err) => obs.error(err)
      });
    });

  }

  confirmNewPassword(email: string, verificationCode: string, password: string) {
    return new Observable(obs => {
      let user = new CognitoUser({Username: email, Pool: this.cognito.getUserPool()});
      user.confirmPassword(verificationCode, password, {
        onSuccess: () => {
          obs.next();
        },
        onFailure: (err) => {
          obs.error(err);
        }
      });
    });
  }
}
